import React from "react"
import PropTypes from "prop-types"
import Seo from "./seo"
import HTMLReactParser from "html-react-parser"

var PostMain = ({title, content})=>{
  return <>
    <Seo title={title} />
    <h1>{title}</h1>
    <div>{HTMLReactParser(content||'')}</div>
  </>
}



PostMain.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

PostMain.defaultProps = {
  title: ``,
  content: ``,
}


export default PostMain;